import { createReducer } from 'redux-act';

import {
    queryDeployments,
    fetchDeployment,
    createDeployment,
    updateDeployment,
    deleteDeployment,
    syncDeployment,
    updateDeploymentState,
    createSSORedirect
} from 'actions';

import {
    objectLoadStart,
    objectLoadError,
    objectLoadComplete,
    objectDelete, resultObjects,
    loadStart,
    loadError,
    loadComplete
} from 'reducers/util';

const initialState = {};

export default createReducer(
  {
      [updateDeploymentState.START]: (state, { id }) => objectLoadStart(state, id),

      [updateDeploymentState.ERROR]: (state, { error, args: { id } }) =>
          objectLoadError(state, id, error),

      [updateDeploymentState.COMPLETE]: (state, { id, data }) =>
          objectLoadComplete(state, id, data),

    [fetchDeployment.START]: (state, { id }) => objectLoadStart(state, id),

    [fetchDeployment.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),

    [fetchDeployment.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),

      [syncDeployment.START]: (state, { id }) => objectLoadStart(state, id),

      [syncDeployment.ERROR]: (state, { error, args: { id } }) =>
          objectLoadError(state, id, error),
      [syncDeployment.COMPLETE]: (state, { id, data }) =>
          objectLoadComplete(state, id, data),

    [queryDeployments.COMPLETE]: (state, { result }) => resultObjects(state, result),

    [createDeployment.START]: (state, { id }) => objectLoadStart(state, id),

    [createDeployment.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),

    [createDeployment.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),

    [updateDeployment.START]: (state, { id }) => objectLoadStart(state, id),

    [updateDeployment.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),

    [deleteDeployment.COMPLETE]: (state, { id }) => objectDelete(state, id),
    [createSSORedirect.START]: (state) => loadStart(state),
    [createSSORedirect.ERROR]: (state, { error }) => loadError(state, error),
    [createSSORedirect.COMPLETE]: (state, { url }) => loadComplete(url),

  },
  initialState
);
