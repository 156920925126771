import { endpoint } from 'app/api';
import asyncActionCreator from 'actions/asyncActionCreator';

export const createAccountRedirect = asyncActionCreator(
  () => async () => {
    const response = await endpoint.get('account/redirect');
    return {url: response.data.url};
  },
  { name: 'FETCH_ACC_REDIR' }
);

export const createAPIRedirect = asyncActionCreator(
  () => async () => {
    const response = await endpoint.get('api/signup');
    return {url: response.data.url};
  },
  { name: 'FETCH_API_RECDIR' }
);

export const createPaymentRedirect = asyncActionCreator(
    (nexus, point, qty) => async () => {
        const response = await endpoint.post('account/payment', {"nexus": nexus, "point": point, "qty": qty });
        return {url: response.data.url};
    },
    { name: 'FETCH_ACC_REDIR' }
);
export const fetchAccountInfo = asyncActionCreator(
  () => async () => {
    const response = await endpoint.get('account');
    return { statistics: response.data };
  },
  { name: 'FETCH_STATISTICS' }
);

export const fetchAPITokens = asyncActionCreator(
  () => async () => {
    const response = await endpoint.get('api/token');
    return response.data;
  },
  { name: 'FETCH_API_DETAILS' }
);

export const resetAPITokens = asyncActionCreator(
  () => async () => {
    const response = await endpoint.post('api/token/roll');
    return response.data;
  },
  { name: 'FETCH_API_DETAILS' }
);
