import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import ErrorScreen from 'components/Screen/ErrorScreen';
import {compose} from "redux";
import {connect} from "react-redux";
import withRouter from "app/withRouter";
import {fetchVerify} from "actions";
import {selectVerify} from "../../selectors";
import {showResponseToast} from "../../app/toast";


const messages = defineMessages({
  verified: {
    id: 'error.screen.not_found',
    defaultMessage: 'Your identity is, or is being verified. Your report is on it\'s way soon.',
  },
});



export class VerifyScreen extends React.Component {
  async componentDidMount() {
    const {params: {appId}, verify, intl} = this.props
    const {id, data} = await this.props.fetchVerify({appId}).catch((e) => {
      showResponseToast(e.response, intl);
    });
    if (data.success === "ok"){
      if (data.url){
        window.location.href = data.url;
      }

    }
  }
  render() {
    const { intl } = this.props;
    return <ErrorScreen title={intl.formatMessage(messages.verified)} icon={"tick"} />;
  }
}
const mapStateToProps = (state, ownProps) => {
  const { appId } = ownProps.params;
  const { verify } = selectVerify(state, appId)
  return { verify }
};


export default compose(
    withRouter,
    connect(mapStateToProps, { fetchVerify }),
    injectIntl,
)(VerifyScreen)