import queryString from 'query-string';

export default function getCollectionLink({ collection, mode, hash, search }) {
  if (!collection?.id) {
    return null;
  }
  const collectionId = collection.id;

  return {
    pathname: `/deployments/${collectionId}`,
    hash: queryString.stringify({ mode, ...hash }),
    search,
  };
}
