import React from 'react';
import { connect } from 'react-redux';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { AppItem, LinkMenuItem } from 'components/common';

import withRouter from 'app/withRouter';
import { queryRoles } from 'actions';
import { groupsQuery } from 'queries';
import { selectRolesResult, selectCurrentRole } from 'selectors';

import './Dashboard.scss';

const messages = defineMessages({
  deployments: {
    id: 'dashboard.deployments',
    defaultMessage: 'Deployments',
  },
  alerts: {
    id: 'dashboard.alerts',
    defaultMessage: 'Alerts',
  },
  users: {
    id: 'dashboard.users',
    defaultMessage: 'Users',
  },
  subjects: {
    id: 'dashboard.subjects',
    defaultMessage: 'Subjects',
  },
  reports: {
    id: 'dashboard.reports',
    defaultMessage: 'Reports',
  },
  settings: {
    id: 'dashboard.settings',
    defaultMessage: 'Settings',
  },
  billing: {
    id: 'dashboard.billing',
    defaultMessage: 'Billing',
  },
  api: {
    id: 'dashboard.api',
    defaultMessage: 'API',
  },
  support: {
    id: 'dashboard.support',
    defaultMessage: 'Support',
  },
});

class Dashboard extends React.Component {
  render() {
    const { intl, location } = this.props;
    const current = location.pathname;

    return (
      <div className="Dashboard">
        <div className="Dashboard__inner-container">
          <div className="Dashboard__menu">
            <Menu>
              <li className="bp4-menu-header">
                <h6 className="bp4-heading">
                  <FormattedMessage
                    id="dashboard.activity"
                    defaultMessage="Trace"
                  />
                </h6>
              </li>
              <LinkMenuItem
                icon="full-stacked-chart"
                text={intl.formatMessage(messages.deployments)}
                to="/trace/deployments"
                active={current === '/trace/deployments'}
              />
              <MenuDivider />
              <li className="bp4-menu-header">
                <h6 className="bp4-heading">
                  <FormattedMessage
                    id="dashboard.workspace"
                    defaultMessage="Point"
                  />
                </h6>
              </li>
              <LinkMenuItem
                icon="person"
                text={intl.formatMessage(messages.subjects)}
                to="/point/subjects"
                active={current === '/point/subjects'}
              />
              <LinkMenuItem
                icon="new-person"
                text={intl.formatMessage(messages.users)}
                to="/point/users"
                active={current === '/point/users'}
              />
              <MenuDivider />
              <LinkMenuItem
                icon="layout-auto"
                text={intl.formatMessage(messages.api)}
                to="/manage-api"
                active={current === '/manage-api'}
              />
              <LinkMenuItem
                icon="cog"
                text={intl.formatMessage(messages.settings)}
                to="/settings"
                active={current === '/settings'}
              />
              <MenuItem
                icon="lifesaver"
                text={intl.formatMessage(messages.support)}
                href="https://thestricsecuritygroup.atlassian.net/servicedesk/customer/portal/1"
              />
              <MenuDivider />
              <AppItem />
            </Menu>
          </div>
          <div className="Dashboard__body">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = groupsQuery(location);
  return {
    role: selectCurrentRole(state),
    groupsQuery: query,
    groupsResult: selectRolesResult(state, query),
  };
};

Dashboard = injectIntl(Dashboard);
Dashboard = connect(mapStateToProps, { queryRoles })(Dashboard);
Dashboard = withRouter(Dashboard);
export default Dashboard;
