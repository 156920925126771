import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';
import {queryEndpoint} from "./util";

export const fetchSubject = asyncActionCreator(
  ({ subjectId }) =>
    async () => {
      const response = await endpoint.get(`subjects/${subjectId}`);
      return { id: subjectId, data: response.data.data };
    },
  { name: 'FETCH_SUBJECT' }
);

export const queryReports = asyncActionCreator(
    (query) => async () => queryEndpoint(query),
    { name: 'QUERY_REPORTS' }
);

export const querySubjects = asyncActionCreator(
    (query) => async () => queryEndpoint(query),
    { name: 'QUERY_SUBJECTS' }
);


export const createSubject = asyncActionCreator(
  ({ subject }) =>
    async () => {
      const config = { params: { sync: true } };
      // const payload = subject.toJSON();
      const response = await endpoint.post(
        `subjects`,
          subject,
        config
      );
      return { id: response.data.id, data: response.data };
    },
  { name: 'CREATE_SUBJECT' }
);

export const updateSubject = asyncActionCreator(
  ({subject}) => async () => {
    const response = await endpoint.patch(
      `subjects/${subject.id}`,
      subject,
      {}
    );
    return { id: subject.id, data: response.data };
  },
  { name: 'UPDATE_SUBJECT' }
);

export const deleteSubject = asyncActionCreator(
  ({subjectId}) => async () => {
    const response = await endpoint.delete(`subjects/${subjectId}`, {});
    return { id: subjectId, data: response.data };
  },
  { name: 'DELETE_SUBJECT' }
);


export const startReport = asyncActionCreator(
    ({subjectId}) => async () => {
        const config = { params: { sync: true } };
        const response = await endpoint.put(`subjects/${subjectId}/report`, config);
        return { id: subjectId, data: response.data };
    },
    { name: 'START_REPORT' }
);
