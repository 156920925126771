import { createReducer } from 'redux-act';
import { createAccountRedirect, createPaymentRedirect, fetchAPITokens, createAPIRedirect } from 'actions';
import { loadState, loadStart, loadError, loadComplete } from 'reducers/util';

const initialState = loadState();

export default createReducer(
  {
      [createAccountRedirect.START]: (state) => loadStart(state),
      [createAccountRedirect.ERROR]: (state, { error }) => loadError(state, error),
      [createAccountRedirect.COMPLETE]: (state, { url }) => loadComplete(url),
      [createAPIRedirect.START]: (state) => loadStart(state),
      [createAPIRedirect.ERROR]: (state, { error }) => loadError(state, error),
      [createAPIRedirect.COMPLETE]: (state, { url }) => loadComplete(url),
      [createPaymentRedirect.START]: (state) => loadStart(state),
      [createPaymentRedirect.ERROR]: (state, { error }) => loadError(state, error),
      [createPaymentRedirect.COMPLETE]: (state, { url }) => loadComplete(url),

      [fetchAPITokens.START]: (state) => loadStart(state),
      [fetchAPITokens.ERROR]: (state, {error}) =>  loadError(state, error),
      [fetchAPITokens.COMPLETE]: (state, {tokens} ) => loadComplete(state, tokens),
  },
  initialState
);
