import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {Icon, H4, ProgressBar, Intent} from '@blueprintjs/core';
import {
    Date,
    Skeleton,
    Summary,
    LinkMenuItem
} from 'components/common';

class ReportsIndexItem extends PureComponent {
  renderSkeleton = () => (
    <li className="index-item">
      <H4 className="index-item__title">
        <Skeleton.Text type="span" length={20} />
      </H4>
      <Skeleton.Text className="index-item__summary" type="p" length={200} />
      <p className="index-item__details">
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
      </p>
    </li>
  );

  render() {
    const { report, isPending } = this.props;

    if (isPending || !report.id) {
      return this.renderSkeleton();
    }

    return (
      <li className="index-item" key={report.id}>
        <H4 className="index-item__title">
            {report.status === "requested" && (
                <ProgressBar intent={Intent.NONE} value={25} />
            )}
            {report.status === "creating" && (
                <ProgressBar intent={Intent.NONE} value={50} />
            )}
            {report.status === "exporting" && (
                <ProgressBar intent={Intent.NONE} value={75} />
            )}
            {report.status === "failed" && (
                <span>This Report Failed</span>
            )}
            {report.status === "ready" && (
                <a href={report.download_url} target={"_blank"}>
                    {report.title}
                </a>
            )}
        </H4>
          <Summary
              text={`Created by: ${report.created_by}. This report is ${report.status}.`}
              className="index-item__summary"
              truncate={2}
          />
        <p className="index-item__details">
          <span className="index-item__details__item">
            <Icon icon="time" />
            <FormattedMessage
              id="collection.last_updated"
              defaultMessage="Requested at {date}"
              values={{
                date: <Date value={report.created_at} />,
              }}
            />
          </span>
        </p>
      </li>
    );
  }
}

export default ReportsIndexItem;
