import React, { Component, Suspense } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { fetchMetadata, fetchMessages } from 'actions';
import {
  selectSession,
  selectMetadata,
  selectMessages,
  selectPinnedMessage,
} from 'selectors';
import Navbar from 'components/Navbar/Navbar';
import MessageBanner from 'components/MessageBanner/MessageBanner';

import NotFoundScreen from 'screens/NotFoundScreen/NotFoundScreen';
import OAuthScreen from 'screens/OAuthScreen/OAuthScreen';
import LogoutScreen from 'screens/LogoutScreen/LogoutScreen';
import ActivateScreen from 'screens/ActivateScreen/ActivateScreen';
import HomeViewScreen from 'screens/HomeViewScreen/HomeViewScreen';
import PagesScreen from 'screens/PagesScreen/PagesScreen';
import SettingsScreen from 'screens/SettingsScreen/SettingsScreen';
import DeploymentsIndexScreen from "screens/DeploymentsIndexScreen/DeploymentsIndexScreen";
import SubjectsScreen from "screens/SubjectsScreen/SubjectsScreen";
import DeploymentCreateScreen from "screens/DeploymentCreateScreen/DeploymentCreateScreen";
import UsersScreen from "screens/UsersScreen/UsersScreen";
import UserIndexScreen from "screens/UserIndexScreen/UserIndexScreen";
import SubjectIndexScreen from "screens/SubjectIndexScreen/SubjectIndexScreen";
import VerifyScreen from "screens/VerifyScreen/VerifyScreen";
import APIScreen from "screens/APIScreen/APIScreen";

import './Router.scss';

const MESSAGES_INTERVAL = 15 * 60 * 1000; // every 15 minutes

class Router extends Component {
  componentDidMount() {
    this.fetchIfNeeded();
    this.setMessagesInterval();
  }

  componentDidUpdate() {
    this.fetchIfNeeded();
  }

  componentWillUnmount() {
    this.clearMessagesInterval();
  }

  fetchIfNeeded() {
    const { metadata, messages } = this.props;

    if (metadata.shouldLoad) {
      this.props.fetchMetadata();
    }

    if (messages.shouldLoad) {
      this.fetchMessages();
    }
  }

  fetchMessages() {
    const { metadata } = this.props;

    if (metadata?.app?.messages_url) {
      this.props.fetchMessages(metadata.app.messages_url);
    }
  }

  setMessagesInterval() {
    const id = setInterval(() => this.fetchMessages(), MESSAGES_INTERVAL);
    this.setState(() => ({ messagesInterval: id }));
  }

  clearMessagesInterval() {
    if (this.state?.messagesInterval) {
      clearInterval(this.state.messagesInterval);
    }
  }

  render() {
    const { metadata, session, pinnedMessage } = this.props;
    const isLoaded = metadata && metadata.app && session;

    const Loading = (
        <div className="RouterLoading">
          <div className="spinner">
            <Spinner className="bp4-large" />
          </div>
        </div>
    );

    if (!isLoaded) {
      return Loading;
    }

    return (
        <>
          <Navbar />
          <MessageBanner message={pinnedMessage} />
          <Suspense fallback={Loading}>
            <Routes>
              <Route path="oauth" element={<OAuthScreen />} />
              <Route path="logout" element={<LogoutScreen />} />
              <Route path="pages/:page" element={<PagesScreen />} />
              <Route path="activate/:code" element={<ActivateScreen />} />
              <Route path="point/subjects" element={<SubjectIndexScreen />} />
              <Route path="trace/deployments" element={<DeploymentsIndexScreen />} />
              <Route path="point/users" element={<UserIndexScreen />} />
              <Route path="point/users/:userId" element={<UsersScreen />} />
              <Route path="verify/:appId" element={<VerifyScreen />} />
              <Route path="verify/:appId/done" element={<VerifyScreen />} />
              <Route exact path="point/subjects/:subjectId" element={<SubjectsScreen />} />
              <Route exact path="trace/deployments/:deploymentId" element={<DeploymentCreateScreen />} />
              <Route path="settings" element={<SettingsScreen />} />
              <Route path="manage-api" element={<APIScreen />} />
              <Route path="/" element={<HomeViewScreen />} />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </Suspense>
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  messages: selectMessages(state),
  pinnedMessage: selectPinnedMessage(state),
  session: selectSession(state),
});

export default connect(mapStateToProps, {
  fetchMetadata,
  fetchMessages,
})(Router);