import isNil from 'lodash/isNil';
import castArray from 'lodash/castArray';
// eslint-disable-next-line import/named
import { Values } from 'followthemoney';

export function ensureArray(values: Values) {
  if (isNil(values)) {
    return [];
  }
  return castArray(values);
}
