import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, H4 } from '@blueprintjs/core';
import {
    Date,
    Skeleton, Summary,
} from 'components/common';
import {Link} from "react-router-dom";

class SubjectIndexItem extends PureComponent {
  renderSkeleton = () => (
    <li className="index-item">
      <H4 className="index-item__title">
        <Skeleton.Text type="span" length={20} />
      </H4>
      <Skeleton.Text className="index-item__summary" type="p" length={200} />
      <p className="index-item__details">
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
      </p>
    </li>
  );

  render() {
    const { subject, isPending } = this.props;

    if (isPending || !subject.id) {
      return this.renderSkeleton();
    }

    return (
      <li className="index-item" key={subject.id}>
        <H4 className="index-item__title">
            <Link to={`/point/subjects/${subject.id}`}>
                {subject.person.first_name} {subject.person.last_name}
            </Link>
        </H4>
          <Summary
              text={subject.person.email}
              className="index-item__summary"
              truncate={2}
          />
        <p className="index-item__details">
          <span className="index-item__details__item">
            <Icon icon="time" />
            <FormattedMessage
              id="collection.last_updated"
              defaultMessage="Last updated {date}"
              values={{
                date: <Date value={subject._meta.updated_at} />,
              }}
            />
          </span>
        </p>
      </li>
    );
  }
}

export default SubjectIndexItem;
