import React, { PureComponent } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { selectMetadata } from 'selectors';

class AppItem extends PureComponent {
  render() {
    const { app } = this.props;
    if (!app) {
      return null;
    }
    const message = (
      <FormattedMessage
        id="footer.cloud"
        defaultMessage="Cloud v{version}"
        values={{
          version: app.version,
        }}
      />
    );
    return (
      <MenuItem
        className="bp4-text-disabled"
        icon="code"
        text={message}
        href="https://getfootprint.com.au"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  app: selectMetadata(state).app,
});

export default connect(mapStateToProps)(AppItem);
