import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, H4 } from '@blueprintjs/core';
import {
    Count,
    Date,
    Skeleton, Summary,
} from 'components/common';
import {Link} from "react-router-dom";

class UserIndexItem extends PureComponent {
  renderSkeleton = () => (
    <li className="index-item">
      <H4 className="index-item__title">
        <Skeleton.Text type="span" length={20} />
      </H4>
      <Skeleton.Text className="index-item__summary" type="p" length={200} />
      <p className="index-item__details">
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
        <Skeleton.Text
          className="index-item__details__item"
          type="span"
          length={20}
        />
      </p>
    </li>
  );

  render() {
    const { user, isPending } = this.props;

    if (isPending || !user.id) {
      return this.renderSkeleton();
    }

    return (
      <li className="index-item" key={user.id}>
        <H4 className="index-item__title">
            <Link to={user.is_me ? '/settings' : `/point/users/${user.id}`}>
                {user.email} {user.is_me ? "(You)" : ""}
            </Link>
        </H4>
        <p className="index-item__details">
          <span className="index-item__details__item">
            <Icon icon="build" />
            <FormattedMessage
              id="collection.last_updated"
              defaultMessage="Roles: {roles}."
              values={{
                  roles: user.roles_friendly
              }}
            />

          </span>
        </p>
      </li>
    );
  }
}

export default UserIndexItem;
