import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Button,
  TextArea,
  FormGroup,
  InputGroup,
  Intent, Position, Drawer,
} from '@blueprintjs/core';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { connect } from 'react-redux';
import withRouter from 'app/withRouter';
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import {updateSubject, fetchSubject, deleteSubject, createSubject, startReport} from 'actions';
import {
  selectCurrentRole,
  selectMetadata,
  selectSubject
} from 'selectors';
import './SubjectsScreen.scss';
import {compose} from "redux";
import {Skeleton} from "../../components/common";
import {showResponseToast, showSuccessToast} from "../../app/toast";
import {reportsQuery} from "queries";
import ReportsIndex from "components/ReportsIndex/ReportsIndex";

const messages = defineMessages({
  title: {
    id: 'settings.title',
    defaultMessage: 'Create Subject',
  },
  first: {
    id: 'settings.first',
    defaultMessage: 'First Name',
  },
  address: {
    id: 'settings.address',
    defaultMessage: 'Address',
  },
  phone: {
    id: 'settings.address',
    defaultMessage: 'Phone Number',
  },
  last: {
    id: 'settings.last',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'settings.email',
    defaultMessage: 'Email Address',
  },
  save_button: {
    id: 'settings.save',
    defaultMessage: 'Update',
  },
  create_button: {
    id: 'settings.create',
    defaultMessage: 'Create',
  },
  delete_button: {
    id: 'settings.delete',
    defaultMessage: 'Delete',
  },
  req_button: {
    id: 'settings.req',
    defaultMessage: 'Request Report',
  },
  notes: {
    id: 'settings.notes',
    defaultMessage: 'Notes',
  },
  dob: {
    id: 'settings.dob',
    defaultMessage: 'Date of Birth',
  },
});

let buttonName = "Create";
let buttonIntent = Intent.PRIMARY;

export class SubjectsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      role: props.role,
      loadingState: {
        s_createButton: false,
        s_reportButton: false,
        s_deleteButton: false,
        draw: false
      },
      subject: {
        id: "",
        person:{
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          dob: "",
        },
        notes: "",
        address: {
          raw: ""
        },
      }
    }

    this.openReportDraw = this.openReportDraw.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangePersonInput = this.onChangePersonInput.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.pushCreate = this.pushCreate.bind(this);
    this.pushUpdate = this.pushUpdate.bind(this);
    this.deleteSubject = this.deleteSubject.bind(this);
    this.requestReport = this.requestReport.bind(this);
    this.onChangeAddressInput = this.onChangeAddressInput.bind(this);

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const {params:{
      subjectId
    }, intl, navigate} = this.props;

    const {params} = prevProps;

    if ((subjectId !== params.subjectId) && subjectId !== "create") {
      this.props.fetchSubject({subjectId}).catch((e) => {
        showResponseToast(e.response, intl);
      })
      buttonName = "Save"
      buttonIntent = Intent.WARNING
    }
  }


  componentDidMount() {
    const {params: {
      subjectId
    }, intl, navigate} = this.props
    if(subjectId !== "create"){
      const subject = this.props.fetchSubject({subjectId}).catch((e) => {
        showResponseToast(e.response, intl);
        // window.location.pathname = `/point/subjects`
      });
      this.setState({subject});
      buttonName = "Update"
      buttonIntent = Intent.WARNING
    }
  }


  onChangeInput({ target }) {
    const { subject } = this.props;
    subject[target.id] = target.value;
    this.setState({ subject });
  }

  onChangeAddressInput(value, data, event, formattedValue){
    const { subject } = this.props;
    subject.address.raw = value;
    this.setState({ subject });
  }
  onChangePersonInput({ target }) {
    const { subject } = this.props;
    subject.person[target.id] = target.value;
    this.setState({ subject });
  }

  onChangePhone(value, data, event, formattedValue) {
    const { subject } = this.props;
    subject.person.phone = value;
    this.setState({ subject });
  }

  onChangeDate(date){
    alert(date.toString());
    const {subject} = this.props;
    subject.dob = new Date(date.toString());
    this.setState({subject})
  }

  requestReport(e){
    e.preventDefault();
    const {metadata, params: {subjectId}, intl} = this.props;
    const {loadingState} = this.state;
    loadingState.s_reportButton = true;
    this.setState({loadingState});
    this.props.startReport({subjectId}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.s_reportButton = false;
      this.setState({loadingState});
    });
    showSuccessToast("Successfully started report.");
    window.location.href = `${metadata.app.ui_uri}point/subjects/${subjectId}`;
  }

  async deleteSubject(e){
    e.preventDefault();
    const {intl, params:{subjectId}, metadata} = this.props;
    const {loadingState} = this.state;
    loadingState.s_deleteButton = true;
    this.setState({loadingState});
    const {data} = await this.props.deleteSubject({subjectId}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.s_deleteButton = false;
      this.setState({loadingState});
    })
    if(data.status === "ok"){
      showSuccessToast("Successfully deleted subject.");
      window.location.href = `${metadata.app.ui_uri}point/subjects`;
    }
  }

  openReportDraw(){
    const {loadingState} = this.state;
    loadingState.draw = !loadingState.draw;
    this.setState({loadingState})
  }

  pushUpdate(e){
    e.preventDefault();
    const {role, metadata, intl} = this.props;
    const {subject, loadingState} = this.state;
    loadingState.s_createButton = true;
    this.setState({loadingState});
    subject.tenancy = role.activeTenancy
    this.props.updateSubject({subject}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.s_createButton = false;
      this.setState({loadingState});
    })
    window.location.href = `${metadata.app.ui_uri}point/subjects/${subject.id}`;
  }

  async pushCreate(e){
    e.preventDefault();
    const {role, navigate, intl, metadata} = this.props;
    const {subject, loadingState} = this.state;
    loadingState.s_createButton = true;
    this.setState({loadingState});
    subject.tenancy = role.activeTenancy
    const {id, data} = await this.props.createSubject({subject}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.s_createButton = false;
      this.setState({loadingState});
    })
    if(data.status === "ok"){
      showSuccessToast("Successfully created subject.");
      window.location.href = `${metadata.app.ui_uri}point/subjects/${id}`;
    }
  }

  renderForm() {
    const { intl, subject } = this.props;
    const {loadingState} = this.state;
    if (subject.isPending) {
      return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
    }
    let buttons = [<Button className={"btn-space"} onClick={ buttonName === "Create" ? this.pushCreate : this.pushUpdate} loading={loadingState.s_createButton} text={buttonName} intent={buttonIntent} />];
    if(buttonName === "Update"){
      buttons.push(<Button className={"btn-space"} onClick={this.deleteSubject} loading={loadingState.s_deleteButton} text={"Delete"} intent={Intent.DANGER} />);
      buttons.push(<Button className={"btn-space"} onClick={this.requestReport} loading={loadingState.s_reportButton} text={"Request Report"} intent={Intent.PRIMARY} />);
      buttons.push(<Button className={"btn-space"} onClick={this.openReportDraw} text={"View Report"} intent={Intent.PRIMARY} />);
    }
    const subjectAddress = subject.address ? subject?.address.raw : ''
    return (
      <div className="settings-form">
        <FormGroup
          label={intl.formatMessage(messages.first)}
          labelFor="first_name"
        >
          <InputGroup
            id="first_name"
            value={subject.person.first_name}
            onChange={this.onChangePersonInput}
            autoFocus
            placeholder={"Jane"}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.last)}
            labelFor="last_name"
        >
          <InputGroup
              id="last_name"
              value={subject.person.last_name}
              onChange={this.onChangePersonInput}
              placeholder={"Doe"}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.phone)}
            labelFor="phone"
        >
          <PhoneInput
              id={"phone"}
              masks={{au: '... ... ...'}}
              value={subject.person.phone}
              onChange={this.onChangePhone}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.email)}
            labelFor="email"
        >
          <InputGroup
              id="email"
              value={subject.person.email}
              onChange={this.onChangePersonInput}
              placeholder={"jane@example.com"}

          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.dob)}
            labelFor="dob"
        >
          <InputGroup
              id="dob"
              value={subject.person.dob}
              onChange={this.onChangePersonInput}
              placeholder={"20/02/1993"}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.address)}
            labelFor="raw"
        >
          <GooglePlacesAutocomplete
              apiKey={"AIzaSyCZ_cX0P9oogoYhBM-ZrAoPVKHmLZSn2JU"}
                                    selectProps={
            {
              onChange: this.onChangeAddressInput,
              id: "raw",
              defaultInputValue: subjectAddress
            }
          } />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.notes)}
            labelFor="notes"
        >
          <TextArea
              id="notes"
              value={subject.notes}
              onChange={this.onChangeInput}
              placeholder={"Some internal notes."}

          />
        </FormGroup>
        {buttons}
      </div>
    );
  }

  render() {
    const { intl, query } = this.props;
    const {loadingState} = this.state;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className="SettingsScreen"
        requireSession
      >
        <Dashboard>
          <div className="Dashboard__title-container">
            <h5 className="Dashboard__title">
              {intl.formatMessage(messages.title)}
            </h5>
          </div>
          {this.renderForm()}
          <Drawer
              className="EntityPreview"
              title={""}
              isOpen={loadingState.draw}
              hasBackdrop={false}
              onClose={this.openReportDraw}
              autoFocus={false}
              enforceFocus={false}
              position={"right"}
              canOutsideClickClose={true}
              portalClassName="EntityPreview__overlay-container"
          >
            <div className="EntityPreview__content">
              <div className="ItemOverview preview">
                <div className="ItemOverview__heading">
                  <h1 className="ItemOverview__heading__title">
                    Digital Footprint Reports
                  </h1>
                  <span className="ItemOverview__heading__last-viewed bp4-text-muted">
                Here are all the reports we've generated for this subject.
              </span>
                </div>
                <div className="ItemOverview__content">
                  <ReportsIndex
                      query={query}
                      showQueryTags
                      icon="clipboard"
                      placeholder={"Loading Reports"}
                      emptyText={"No reports were found."}
                      noResultsText={"No reports were found."}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        </Dashboard>
      </Screen>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { subjectId } = ownProps.params;
  const metadata = selectMetadata(state);
  const subject = selectSubject(state, subjectId);
  const role = selectCurrentRole(state);
  const query = reportsQuery(ownProps.location, subjectId)
  return {metadata, subject, role, query}
};


export default compose(
    withRouter,
    connect(mapStateToProps, { fetchSubject, updateSubject, createSubject, deleteSubject, startReport }),
    injectIntl
)(SubjectsScreen)
