import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import UserIndex from 'components/UserIndex/UserIndex';
import {usersQuery} from 'queries';
import Screen from 'components/Screen/Screen';
import LoadingScreen from 'components/Screen/LoadingScreen';
import Dashboard from 'components/Dashboard/Dashboard';
import { selectCurrentRole } from 'selectors';

import './UserIndexScreen.scss';

const messages = defineMessages({
  title: {
    id: 'notifications.title',
    defaultMessage: 'Users',
  },
  greeting: {
    id: 'notifications.greeting',
    defaultMessage: "Hi {role},",
  },
  placeholder: {
    id: 'collection.index.placeholder',
    defaultMessage: 'Search users...',
  },
  empty: {
    id: 'collection.index.empty',
    defaultMessage: 'No users were found.',
  },
  no_results: {
    id: 'collection.index.no_results',
    defaultMessage: 'No users were found matching this query.',
  },
});

export class UserIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.updateQuery = this.updateQuery.bind(this);
  }

  updateQuery(newQuery) {
    const { navigate, location } = this.props;
    navigate({
      pathname: location.pathname,
      search: newQuery.toLocation(),
    });
  }

  render() {
    const { query, intl, role } = this.props;
    const screenProps = {
      title: intl.formatMessage(messages.title),
      requireSession: true,
      className: 'DeploymentsScreen',
    };

    if (!role.id) {
      return <LoadingScreen {...screenProps} />;
    }

    return (
      <Screen {...screenProps}>
        <Dashboard>
          <div className="Dashboard__title-container">
            <h5 className="Dashboard__title">
              {intl.formatMessage(messages.greeting, { role: role.name || '' })}
            </h5>
            <p className="Dashboard__subheading">
              <FormattedMessage
                id="notification.description"
                defaultMessage="Here are all the user's available in your Footprint Tenancy."
              />
            </p>
          </div>
          <UserIndex
              query={query}
              showQueryTags
              icon="new-person"
              placeholder={intl.formatMessage(messages.placeholder)}
              emptyText={intl.formatMessage(messages.empty)}
              noResultsText={intl.formatMessage(messages.no_results)}
          />
        </Dashboard>
      </Screen>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const role = selectCurrentRole(state)
  const query = usersQuery(location, role.activeTenancy ? role.activeTenancy : "default");
  return {
    query,
    role
  };
};

export default compose(
    withRouter,
    connect(mapStateToProps, {}),
    injectIntl
)(UserIndexScreen);