import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
} from '@blueprintjs/core';
import 'react-phone-input-2/lib/style.css';

import { connect } from 'react-redux';
import withRouter from 'app/withRouter';
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import { createUser, updateUser, deleteUser, fetchUser } from 'actions';
import { selectCurrentRole, selectMetadata, selectUser } from 'selectors';
import './UsersScreen.scss';
import { compose } from 'redux';
import { Skeleton, CheckboxList } from 'components/common';
import { showResponseToast, showSuccessToast } from 'app/toast';
import { ItemRenderer, MultiSelect } from '@blueprintjs/select';

const messages = defineMessages({
  title: {
    id: 'invite.title',
    defaultMessage: 'Invite User',
  },
  username: {
    id: 'invite.username',
    defaultMessage: 'Username',
  },
  email: {
    id: 'invite.email',
    defaultMessage: 'Email Address',
  },
  save_button: {
    id: 'settings.save',
    defaultMessage: 'Update',
  },
  create_button: {
    id: 'settings.create',
    defaultMessage: 'Create',
  },
  delete_button: {
    id: 'settings.delete',
    defaultMessage: 'Delete',
  },
  roles: {
    id: 'settings.roles',
    defaultMessage: 'Roles',
  },
});

export const ROLE_MAPPINGS = [
  { title: 'Write Subjects', id: 'subjects.write' },
  { title: 'Read Subjects', id: 'subjects.read' },
  { title: 'Write Reports', id: 'reports.write' },
  { title: 'Read Reports', id: 'reports.read' },
  { title: 'Read Deployments', id: 'deployment.read' },
  { title: 'Write Deployments', id: 'deployment.write' },
  { title: 'Administer Billing', id: 'billing.admin' },
];

let buttonName = 'Create';
let buttonIntent = Intent.PRIMARY;

export class UsersScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: {
        u_create: false,
        u_delete: false,
      },
      role: props.role,
      roleItems: [],
      createdItems: [],
      user: {
        id: '',
        name: '',
        email: '',
        roles: [],
      },
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeReportPerm = this.onChangeReportPerm.bind(this);
    this.onChangeSubjectPerm = this.onChangeSubjectPerm.bind(this);
    this.pushCreate = this.pushCreate.bind(this);
    this.pushUpdate = this.pushUpdate.bind(this);
    this.pushDelete = this.pushDelete.bind(this);
    this.renderRoles = this.renderRoles.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handleTagRemove = this.handleTagRemove.bind(this);
    this.deselectRole = this.deselectRole.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.isRoleSelected = this.isRoleSelected.bind(this);
    this.maybeDeleteCreatedRoleFromArrays =
      this.maybeDeleteCreatedRoleFromArrays.bind(this);
    this.maybeAddItemToRolesArray = this.maybeAddItemToRolesArray.bind(this);
  }

  getRoleItemProps(role, { handleClick, handleFocus, modifiers, query }) {
    return {
      active: modifiers.active,
      disabled: modifiers.disabled,
      key: role.id,
      label: role.id,
      onClick: handleClick,
      onFocus: handleFocus,
      text: role.title,
    };
  }

  renderRoles = (role, props) => {
    if (!props.modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        {...this.getRoleItemProps(role, props)}
        selected={this.isRoleSelected(role)}
        shouldDismissPopover={false}
        text={`${role.title}`}
      />
    );
  };

  deselectRole(index) {
    let { user } = this.props;
    const role = user.roles[index];
    const { createdItems: nextCreatedItems, roleItems: nextItems } =
      this.maybeDeleteCreatedRoleFromArrays(
        user.roles,
        this.state.createdItems,
        role
      );
    user.roles = user.roles.filter((_role, i) => i !== index);
    this.setState({
      createdItems: nextCreatedItems,
      user,
      roleItems: nextItems,
    });
  }

  handleTagRemove(_tag, index) {
    this.deselectRole(index);
  }

  getSelectedRoleIndex(role) {
    const {
      user: { roles, isPending },
    } = this.props;
    if (isPending) {
      return 0;
    }
    return roles.indexOf(role);
  }

  isRoleSelected(role) {
    return this.getSelectedRoleIndex(role) !== -1;
  }

  selectRole(role) {
    this.selectRoles([role]);
  }

  addRoleToArray(userRoles, roleToAdd) {
    return [...userRoles, roleToAdd];
  }

  arrayContainsRole(userRoles, roleToFind) {
    return userRoles.some((role) => role.id === roleToFind?.id);
  }

  maybeDeleteCreatedRoleFromArrays(userRoles, roleItems, role) {
    return {
      createdItems: this.deleteRoleFromArray(roleItems, role),
      roleItems: this.deleteRoleFromArray(roleItems, role),
      userRoles: this.deleteRoleFromArray(userRoles, role),
    };
  }

  deleteRoleFromArray(userRoles, roleToDelete) {
    return userRoles.filter((role) => role !== roleToDelete);
  }

  maybeAddItemToRolesArray(userRoles, roleItems, role) {
    return {
      createdItems: this.addRoleToArray(roleItems, role),
      roleItems: this.addRoleToArray(roleItems, role),
      userRoles: this.addRoleToArray(userRoles, role),
    };
  }

  handleRoleSelect(role) {
    if (!this.isRoleSelected(role)) {
      this.selectRole(role);
    } else {
      this.deselectRole(this.getSelectedRoleIndex(role));
    }
  }

  selectRoles(roleToSelect) {
    let { user } = this.props;
    this.setState(({ createdItems, roleItems }) => {
      let nextCreatedItems = createdItems.slice();
      let nextFilms = user.roles.slice();
      let nextItems = roleItems.slice();

      roleToSelect.forEach((role) => {
        const results = this.maybeAddItemToRolesArray(
          nextItems,
          nextCreatedItems,
          role
        );
        nextItems = results.roleItems;
        nextCreatedItems = results.createdItems;
        nextFilms = !this.arrayContainsRole(nextFilms, role)
          ? [...nextFilms, role]
          : nextFilms;
      });
      user.roles = nextFilms;
      return {
        createdItems: nextCreatedItems,
        user,
        roleItems: nextItems,
      };
    });
  }

  filterRole(query, role, _index, exactMatch) {
    const normalizedTitle = role.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${role.title}`.indexOf(normalizedQuery) >= 0;
    }
  }

  componentDidMount() {
    const {
      params: { userId },
      intl,
      metadata,
      role,
    } = this.props;
    if (userId !== 'create') {
      const ten = role.activeTenancy ? role.activeTenancy : 'default';
      const user = this.props
        .fetchUser({ userId, tenancyId: ten })
        .catch((e) => {
          showResponseToast(e.response, intl);
          window.location.href = `${metadata.app.ui_uri}point/users`;
        });
      this.setState({ user });
      buttonName = 'Update';
      buttonIntent = Intent.WARNING;
    }
  }

  onChangeInput({ target }) {
    const { user } = this.props;
    user[target.id] = target.value;
    this.setState({ user });
  }

  onChangeSubjectPerm({ target }) {
    const { user } = this.props;
    user.subjects[target.id] = target.value;
    this.setState({ user });
  }

  onChangeReportPerm({ target }) {
    const { user } = this.props;
    user.reports[target.id] = target.value;
    this.setState({ user });
  }

  async pushDelete(e) {
    e.preventDefault();
    e.target.loading = true;
    const { role, metadata, intl, user, params } = this.props;
    const { loadingState } = this.state;
    loadingState.u_delete = true;
    this.setState({ loadingState });
    const { id, data } = await this.props
      .deleteUser({
        userId: params.userId,
        tenancyId: role.activeTenancy || 'default',
      })
      .catch((e) => {
        showResponseToast(e.response, intl);
        loadingState.u_delete = false;
        this.setState({ loadingState });
      });
    if (data.status === 'ok') {
      loadingState.u_delete = false;
      this.setState({ loadingState });
      showSuccessToast('Successfully Deleted User');
      window.location.href = `${metadata.app.ui_uri}point/users`;
    }
  }
  async pushUpdate(e) {
    e.preventDefault();
    e.target.loading = true;
    const { role, metadata, intl, user, path } = this.props;
    const {
      user: { roles },
      loadingState,
    } = this.state;
    loadingState.u_create = true;
    this.setState({ loadingState });
    user.roles = roles.map((x) => x['id']);
    const { id, data } = await this.props
      .updateUser({ user, tenancyId: role.activeTenancy || 'default' })
      .catch((e) => {
        showResponseToast(e.response, intl);
        loadingState.u_create = false;
        this.setState({ loadingState });
      });
    if (data.status === 'ok') {
      showSuccessToast('Successfully Updated Users Roles');
      loadingState.u_create = false;
      this.setState({ loadingState });
      window.location.href = `${metadata.app.ui_uri}point/users/${id}`;
    }
  }

  async pushCreate(e) {
    e.preventDefault();
    e.target.loading = true;
    const { role, metadata, intl, user } = this.props;
    const {
      user: { roles },
      loadingState,
    } = this.state;
    loadingState.u_create = true;
    this.setState({ loadingState });
    user.roles = roles.map((x) => x['id']);
    const { id, data } = await this.props
      .createUser({ user, tenancyId: role.activeTenancy || 'default' })
      .catch((e) => {
        showResponseToast(e.response, intl);
        loadingState.u_create = false;
        this.setState({ loadingState });
      });
    if (data.status === 'ok') {
      showSuccessToast('Successfully Invited User');
      loadingState.u_create = false;
      this.setState({ loadingState });
      window.location.href = `${metadata.app.ui_uri}point/users`;
    }
  }

  renderTag(role) {
    return `${role.title}`;
  }

  renderForm() {
    const { intl, user } = this.props;
    const { loadingState } = this.state;
    if (user.isPending) {
      return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
    }
    let buttons = [
      <Button
        className={'btn-space'}
        loading={loadingState.u_create}
        onClick={buttonName === 'Create' ? this.pushCreate : this.pushUpdate}
        text={buttonName}
        intent={buttonIntent}
      />,
    ];
    if (buttonName === 'Update') {
      buttons.push(
        <Button
          className={'btn-space'}
          loading={loadingState.u_delete}
          onClick={this.pushDelete}
          text={'Delete'}
          intent={Intent.DANGER}
        />
      );
    }
    return (
      <div className="settings-form">
        <FormGroup
          label={intl.formatMessage(messages.email)}
          labelFor="email"
          helperText={
            buttonName === 'Create'
              ? 'The email used by this user.'
              : "You can't change this value."
          }
        >
          <InputGroup
            id="email"
            readOnly={buttonName === 'Update'}
            value={user.email}
            onChange={this.onChangeInput}
            placeholder={'jane@example.com'}
          />
        </FormGroup>
        <FormGroup label={intl.formatMessage(messages.roles)} labelFor="roles">
          <MultiSelect
            itemRenderer={this.renderRoles}
            items={ROLE_MAPPINGS}
            itemPredicate={this.filterRole}
            noResults={
              <MenuItem
                disabled={true}
                text="No roles."
                roleStructure="listoption"
              />
            }
            onItemSelect={this.handleRoleSelect}
            fill={true}
            tagRenderer={this.renderTag}
            placeholder={'Select Roles'}
            tagInputProps={{
              onRemove: this.handleTagRemove,
              tagProps: {
                intent: Intent.NONE,
                minimal: false,
              },
            }}
            selectedItems={user.roles}
          />
        </FormGroup>
        {buttons}
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className="SettingsScreen"
        requireSession
      >
        <Dashboard>
          <div className="Dashboard__title-container">
            <h5 className="Dashboard__title">
              {intl.formatMessage(messages.title)}
            </h5>
            <p>
              Invite users to collaborate. The user will be invited to sign up
              for an account, or accept an invite to collaborate if they already
              have a Footprint account.
            </p>
          </div>
          {this.renderForm()}
        </Dashboard>
      </Screen>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps.params;
  const metadata = selectMetadata(state);
  const user = selectUser(state, userId);
  const role = selectCurrentRole(state);
  return { metadata, user, role };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { createUser, updateUser, deleteUser, fetchUser }),
  injectIntl
)(UsersScreen);
