import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';
import {queryEndpoint} from "./util";

export const fetchDeployment = asyncActionCreator(
  ({ deploymentId }) =>
    async () => {
      const response = await endpoint.get(`deployments/${deploymentId}`);
      return { id: response.data.data.id, data: response.data.data }
    },
  { name: 'FETCH_DEPLOYMENT' }
);

export const queryDeployments = asyncActionCreator(
    (query) => async () => queryEndpoint(query),
    { name: 'QUERY_DEPLOYMENTS' }
);


export const updateDeploymentState = asyncActionCreator(
    ({ deployment }) =>
        async () => {
            return {id: "current-deployment", data: deployment}
        },
    { name: 'UPDATE_DEPLOYMENT_STATE' }
)

export const createDeployment = asyncActionCreator(
  ({ deployment }) =>
    async () => {
      const config = { params: { sync: true } };
      const response = await endpoint.post(
        `deployments`,
          deployment,
          config
      );
      return { id: response.data.id, data: response.data, raw: response };
    },
  { name: 'CREATE_DEPLOYMENT' }
);

export const updateDeployment = asyncActionCreator(
  ({deployment}) => async () => {
    const config = { params: { sync: true } };
    const response = await endpoint.patch(
      `deployments/${deployment.id}`,
        deployment,
        config
    );
    return { id: response.data.id, data: response.data };
  },
  { name: 'UPDATE_DEPLOYMENT' }
);

export const syncDeployment = asyncActionCreator(
    ({id}) => async () => {
        const config = { params: { sync: true } };
        const response = await endpoint.post(
            `deployments/${id}/sync`,
            config
        );
        return { id, data: response.data };
    },
    { name: 'SYNC_DEPLOYMENT' }
);

export const deleteDeployment = asyncActionCreator(
  ({id}) => async () => {
    const config = { params: { sync: true } };
    const response = await endpoint.delete(`deployments/${id}`, config);
    return { id: id, data: response.data };
  },
  { name: 'DELETE_DEPLOYMENT' }
);


export const createSSORedirect = asyncActionCreator(
  (deployment) => async () => {
      const response = await endpoint.get(`deployments/${deployment.id}/sso`);
      return {url: response.data.url};
  },
  { name: 'FETCH_SSO_REDIR' }
);

