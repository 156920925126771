import {createReducer} from 'redux-act';

import {
    fetchSubject,
    createSubject,
    updateSubject,
    deleteSubject,
    querySubjects,
    startReport,
    queryReports
} from 'actions';

import {
    objectLoadStart,
    objectLoadError,
    objectLoadComplete,
    objectDelete,
    resultObjects
} from 'reducers/util';

const initialState = {};

export default createReducer(
    {
        [fetchSubject.START]: (state, {id}) => objectLoadStart(state, id),
        [fetchSubject.ERROR]: (state, {error, args: {id}}) =>
            objectLoadError(state, id, error),
        [fetchSubject.COMPLETE]: (state, {id, data}) =>
            objectLoadComplete(state, id, data),

        [createSubject.START]: (state, {id}) => objectLoadStart(state, id),
        [createSubject.ERROR]: (state, {error, args: {id}}) =>
            objectLoadError(state, id, error),
        [createSubject.COMPLETE]: (state, {id, data}) =>
            objectLoadComplete(state, id, data),

        [updateSubject.START]: (state, {id}) => objectLoadStart(state, id),
        [updateSubject.ERROR]: (state, {error, args: {id}}) =>
            objectLoadError(state, id, error),
        [updateSubject.COMPLETE]: (state, {id, data}) =>
            objectLoadComplete(state, id, data),

        [startReport.COMPLETE]: (state, {id, data}) =>
            objectLoadComplete(state, id, data),
        [startReport.START]: (state, {id}) => objectLoadStart(state, id),
        [startReport.COMPLETE]: (state, {id, data}) =>
            objectLoadComplete(state, id, data),

        [deleteSubject.COMPLETE]: (state, {id}) => objectDelete(state, id),
        [querySubjects.COMPLETE]: (state, {result}) =>
            resultObjects(state, result),
        [queryReports.COMPLETE]: (state, {result}) =>
            resultObjects(state, result),
    },
    initialState
);
