import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {Alignment, Button, Classes, MenuItem, Navbar as Bp3Navbar} from '@blueprintjs/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import c from 'classnames';
import withRouter from 'app/withRouter';
import AuthButtons from 'components/AuthButtons/AuthButtons';
import CreateButtons from 'components/CreateButtons/CreateButtons';
import {
  selectMetadata,
  selectSession,
  selectPages,
  selectEntitiesResult,
  selectCurrentRole,
} from 'selectors';
import { entitiesQuery } from 'queries';
import './Navbar.scss';
import SelectWrapper from "../common/SelectWrapper";
import {Skeleton} from "../common";
import {setChosenTenancy, getChosenTenancy} from 'app/storage';

export class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSearchOpen: false,
      advancedSearchOpen: false,
      role: props.role,
    };

    this.navbarRef = React.createRef();
    this.inputRef = React.createRef();
    this.doRenderOfTenancies = this.doRenderOfTenancies.bind(this);
    this.renderTenancies = this.renderTenancies.bind(this);
    this.onSelectTenancy = this.onSelectTenancy.bind(this);

  }

  renderTenancies(tenancy, { handleClick, modifiers }) {
    return (
        <MenuItem
            className={modifiers.active ? Classes.ACTIVE : ''}
            key={tenancy}
            onClick={handleClick}
            text={tenancy}
        />
    );
  }

  onSelectTenancy(tenancy, event) {
    event.stopPropagation();
    setChosenTenancy(tenancy);
  }


  doRenderOfTenancies(){
    const {role} = this.props;
    if (!role.tenancies_choose){
      return <></>
    }
    if (!role.id && role.isPending) {
      return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
    }
    return (
        <SelectWrapper
            itemRenderer={this.renderTenancies}
            items={role.tenancies}
            onItemSelect={this.onSelectTenancy}
            selectedItem={getChosenTenancy()}
            popoverProps={{
              minimal: false,
              fill: true,
            }}
            inputProps={{
              fill: true,
            }}
            filterable={false}
        >
          <Button
              fill
              text={"Tenancies"}
              alignText={Alignment.LEFT}
              icon="bank-account"
              rightIcon="caret-down"
          />
        </SelectWrapper>
    )
  }

  render() {
    const { metadata, session } = this.props;
    const { mobileSearchOpen } = this.state;
    return (
        <>
          <div className="Navbar" ref={this.navbarRef}>
            <Bp3Navbar id="Navbar" className="bp4-dark">
              <Bp3Navbar.Group
                  align={Alignment.LEFT}
                  className={c('Navbar__left-group', { hide: mobileSearchOpen })}
              >
                <Link to="/" className="Navbar__home-link">
                  {!!metadata.app.logo && (
                      <img src={metadata.app.logo} alt={metadata.app.title} />
                  )}
                  {!!metadata.app.title && (
                      <span className="Navbar__home-link__text">
                    {metadata.app.title}
                  </span>
                  )}
                </Link>
              </Bp3Navbar.Group>
              <Bp3Navbar.Group
                  align={Alignment.CENTER}
                  className={c('Navbar__middle-group', {
                    'mobile-force-open': mobileSearchOpen,
                  })}
              >

              </Bp3Navbar.Group>
              <Bp3Navbar.Group
                  align={Alignment.RIGHT}
                  className="Navbar__right-group"
                  id="navbarSupportedContent"
              >
                {session.loggedIn && (
                    this.doRenderOfTenancies()
                )}
                {session.loggedIn && (
                    <CreateButtons className={c({ hide: mobileSearchOpen })} />
                )}
                <Bp3Navbar.Divider
                    className={c({ 'mobile-hidden': mobileSearchOpen })}
                />
                <div className={c({ 'mobile-hidden': mobileSearchOpen })}>
                  <AuthButtons className={c({ hide: mobileSearchOpen })} />
                </div>
              </Bp3Navbar.Group>
            </Bp3Navbar>
          </div>

        </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = entitiesQuery(location);
  return {
    query,
    result: selectEntitiesResult(state, query),
    isHomepage: location.pathname === '/',
    metadata: selectMetadata(state),
    session: selectSession(state),
    pages: selectPages(state),
    role: selectCurrentRole(state)
  };
};

export default compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(Navbar);
