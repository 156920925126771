import { createAction } from 'redux-act';

export { queryRoles, fetchRole, suggestRoles, updateRole } from './roleActions';
export { createAlert, deleteAlert, queryAlerts } from './alertActions';
export { queryNotifications } from './notificationActions';
export { setConfigValue } from './configActions';
export { ingestDocument } from './documentActions';
export {
  createCollection,
  deleteCollection,
  fetchCollection,
  fetchCollectionPermissions,
  queryCollections,
  queryCollectionXref,
  triggerCollectionXref,
  triggerCollectionXrefDownload,
  triggerCollectionCancel,
  updateCollection,
  updateCollectionPermissions,
} from './collectionActions';
export {
  createEntity,
  deleteEntity,
  fetchEntity,
  fetchEntityTags,
  queryEntities,
  querySimilar,
  queryEntityExpand,
  updateEntity,
} from './entityActions';
export {
  createEntityMapping,
  deleteEntityMapping,
  fetchEntityMapping,
  flushEntityMapping,
  queryMappings,
  updateEntityMapping,
} from './entityMappingActions';
export {
  createEntitySetMutate,
  createEntitySetNoMutate,
  deleteEntitySet,
  entitySetAddEntity,
  fetchDiagramEmbed,
  queryEntitySetItems,
  updateEntitySetItemMutate,
  updateEntitySetItemNoMutate,
  fetchEntitySet,
  queryEntitySets,
  queryEntitySetEntities,
  updateEntitySet,
} from './entitySetActions';
export {
  queryProfileExpand,
  fetchProfile,
  fetchProfileTags,
  pairwiseJudgement,
} from './profileActions';
export { fetchMessages } from './messagesActions';
export {
  fetchMetadata,
  fetchStatistics,
  fetchSystemStatus,
} from './metadataActions';

export {
  createAccountRedirect,
  createAPIRedirect,
  fetchAccountInfo,
  fetchAPITokens,
  createPaymentRedirect,
  resetAPITokens, 
} from './accountActions'

export {
    fetchSubject,
    querySubjects,
    createSubject,
    deleteSubject,
    updateSubject,
    startReport,
    queryReports
} from './subjectActions'

export {
    fetchUser,
    queryUsers,
    createUser,
    deleteUser,
    updateUser
} from './userActions'


export {
    fetchDeployment,
    updateDeployment,
    syncDeployment,
    createDeployment,
    deleteDeployment,
    queryDeployments,
    updateDeploymentState,
    createSSORedirect,
} from './deploymentActions'

export {
    fetchVerify
} from './verifiyActions'

export { loginWithToken, loginWithPassword, logout } from './sessionActions';
export { fetchExports, triggerQueryExport } from './exportActions';

export { createAction };
export const setLocale = createAction('SET_LOCALE');
export const forceMutate = createAction('MUTATE');
