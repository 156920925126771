import defaultModel_ from './defaultModel.json';
import { IModelDatum } from './model';

export * from './entity'
export * from './model'
export * from './namespace'
export * from './property'
export * from './schema'
export * from './type'
export * from './icons'

export const defaultModel: IModelDatum = defaultModel_;
