import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';
import withRouter from 'app/withRouter';
import {selectCurrentRole, selectReportsResult } from 'selectors';
import {ErrorSection, QueryInfiniteLoad, SearchBox} from 'components/common';
import ReportsIndexItem from './ReportsIndexItem';
import {queryReports} from 'actions'
import './SubjectIndex.scss';
import QueryTags from "../QueryTags/QueryTags";
import SearchActionBar from "../common/SearchActionBar";
import SortingBar from "../SortingBar/SortingBar";
import {Button, Intent} from "@blueprintjs/core";

const messages = defineMessages({
  show_mine: {
    id: 'deployment.index.filter.mine',
    defaultMessage: 'Created by me',
  },
  show_all: {
    id: 'deployment.index.filter.all',
    defaultMessage: 'All',
  },
});

export class ReportsIndex extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.toggleCreatedBy = this.toggleCreatedBy.bind(this);
  }

  onSearch(queryText) {
    const { query } = this.props;
    const newQuery = query.set('q', queryText);
    this.updateQuery(newQuery);
  }

  updateQuery(newQuery) {
    const { navigate, location } = this.props;

    navigate({
      pathname: location.pathname,
      search: newQuery.toLocation(),
    });
  }

  toggleCreatedBy() {
    const { createdByFilterVal, query, role } = this.props;
    const newQuery = createdByFilterVal.length
        ? query.clearFilter('creator_id')
        : query.setFilter('creator_id', role.id);
    this.updateQuery(newQuery);
  }

  renderErrors() {
    const { emptyText, icon, noResultsText, query, result } = this.props;
    const hasQuery = query.hasQuery() || query.hasFilter('creator_id');

    if (result.isError) {
      return <ErrorSection error={result.error} />;
    }
    if (result.total === 0) {
      const message = hasQuery ? noResultsText : emptyText;

      return <ErrorSection icon={icon} title={message} />;
    }

    return null;
  }

  renderResults() {
    const { result } = this.props;
    const skeletonItems = [...Array(10).keys()];

    return (
        <ul className="index">
          {result.results.map((res) => (
              <ReportsIndexItem key={res.id} report={res} />
          ))}
          {result.isPending &&
              skeletonItems.map((item) => (
                  <ReportsIndexItem key={item} isPending />
              ))}
        </ul>
    );
  }

  render() {
    const {
      placeholder,
      query,
      result,
      showQueryTags,
    } = this.props;

    return (
        <div className="CollectionIndex">
          <div className="CollectionIndex__controls">
            <SearchActionBar result={result}>
              <SortingBar
                  query={query}
                  updateQuery={this.updateQuery}
                  sortingFields={['title', 'created_at']}
                  filterButton={
                    <Button
                        text={messages.show_mine.defaultMessage}
                        onClick={this.toggleCreatedBy}
                        minimal
                        intent={Intent.PRIMARY}
                    />
                  }
              />
            </SearchActionBar>
          </div>
          {this.renderErrors()}
          {this.renderResults()}
          <QueryInfiniteLoad
              query={query}
              result={result}
              fetch={this.props.queryReports}
          />
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { query } = ownProps;
  const createdByFilterVal = query.getFilter('creator_id');
  return {
    result: selectReportsResult(state, query),
    role: selectCurrentRole(state),
    createdByFilterVal,
  };
};

export default compose(
    withRouter,
    connect(mapStateToProps, { queryReports }),
    injectIntl
)(ReportsIndex);
