import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';
import { queryEndpoint } from './util';

export const queryCollections = asyncActionCreator(
  (query) => async () => queryEndpoint(query),
  { name: 'QUERY_COLLECTIONS' }
);

export const fetchCollection = asyncActionCreator(
  ({ id, refresh }) =>
    async () => {
      const config = { params: { refresh } };
      const response = await endpoint.get(`investigations/${id}`, config);
      return { id, data: response.data };
    },
  { name: 'FETCH_COLLECTION' }
);

export const createCollection = asyncActionCreator(
  (collection) => async () => {
    const config = { params: { sync: true } };
    const response = await endpoint.post('investigations', collection, config);
    return { id: response.id, data: response.data };
  },
  { name: 'CREATE_COLLECTION' }
);

export const updateCollection = asyncActionCreator(
  (collection) => async () => {
    const config = { params: { sync: true } };
    const response = await endpoint.post(
      `investigations/${collection.id}`,
      collection,
      config
    );
    return { id: collection.id, data: response.data };
  },
  { name: 'UPDATE_COLLECTION' }
);

export const deleteCollection = asyncActionCreator(
  (collection) => async () => {
    const config = { params: { sync: false } };
    await endpoint.delete(`investigations/${collection.id}`, config);
    return { id: collection.id };
  },
  { name: 'DELETE_COLLECTION' }
);

export const fetchCollectionPermissions = asyncActionCreator(
  (id) => async () => {
    const response = await endpoint.get(`investigations/${id}/permissions`);
    response.data.results.sort((first, second) =>
      first.role.name < second.role.name ? -1 : 1
    );
    return { id, data: response.data };
  },
  { name: 'FETCH_COLLECTION_PERMISSIONS' }
);

export const updateCollectionPermissions = asyncActionCreator(
  (id, permissions) => async () => {
    const config = { params: { sync: true } };
    const response = await endpoint.post(
      `investigations/${id}/permissions`,
      permissions,
      config
    );
    return { id, data: response.data };
  },
  { name: 'UPDATE_COLLECTION_PERMISSIONS' }
);

export const queryCollectionXref = asyncActionCreator(
  (query) => async () => queryEndpoint(query),
  { name: 'QUERY_XREF' }
);

export const triggerCollectionXref = asyncActionCreator(
  (id) => async () => {
    const response = await endpoint.post(`investigations/${id}/xref`, {});
    return { data: response.data };
  },
  { name: 'TRIGGER_XREF' }
);

export const triggerCollectionXrefDownload = asyncActionCreator(
  (id) => async () => {
    const response = await endpoint.post(`investigations/${id}/xref.xlsx`, {});
    return { data: response.data };
  },
  { name: 'TRIGGER_XREF_DOWNLOAD' }
);

export const triggerCollectionCancel = asyncActionCreator(
  (id) => async () => {
    const response = await endpoint.delete(`investigations/${id}/status`);
    return { id, data: response.data };
  },
  { name: 'TRIGGER_COLLECTION_CANCEL' }
);
