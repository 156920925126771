import React, { PureComponent } from 'react';

import {
  Collection,
  EntitySet,
  Entity,
  QueryText,
  Role,
  Skeleton,
  ExportLink,
  RelativeTime,
} from 'components/common';

import './Notification.scss';

class Notification extends PureComponent {
  getParam(name) {
    const { notification } = this.props;
    const { params } = notification;
    return <Collection.Link collection={params} icon />;
  }

  renderSkeleton = () => (
    <li className="Notification">
      <div className="notification-action">
        <Skeleton.Text type="span" length={50} />
      </div>
      <div className="timestamp">
        <Skeleton.Text type="span" length={15} />
      </div>
    </li>
  );

  render() {
    const { isPending, notification } = this.props;

    if (isPending) {
      return this.renderSkeleton();
    }
    return (
      <li key={notification.id} className="Notification">
        <div className="notification-action">{notification.content}</div>
        <div className="timestamp">
          <RelativeTime utcDate={notification.notified_at} />
        </div>
      </li>
    );
  }
}

export default Notification;
