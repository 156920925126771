import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { Button, Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';

import { fetchRole } from 'actions';
import {
  selectCurrentRole,
  selectCurrentRoleId,
  selectMetadata,
} from 'selectors';
import AuthenticationDialog from 'dialogs/AuthenticationDialog/AuthenticationDialog';
import { DialogToggleButton } from 'components/Toolbar';
import { Skeleton } from 'components/common';

import './CreateButtons.scss';

const messages = defineMessages({
  notifications: {
    id: 'nav.view_notifications',
    defaultMessage: 'Notifications',
  },
  diagrams: {
    id: 'nav.diagrams',
    defaultMessage: 'Network diagrams',
  },
  timelines: {
    id: 'nav.timelines',
    defaultMessage: 'Timelines',
  },
  lists: {
    id: 'nav.lists',
    defaultMessage: 'Lists',
  },
  settings: {
    id: 'nav.settings',
    defaultMessage: 'Settings',
  },
  signout: {
    id: 'nav.signout',
    defaultMessage: 'Sign out',
  },
  signin: {
    id: 'nav.signin',
    defaultMessage: 'Sign in',
  },
  alerts: {
    id: 'nav.alerts',
    defaultMessage: 'Alerts',
  },
  cases: {
    id: 'nav.menu.cases',
    defaultMessage: 'Investigations',
  },
});

export class CreateButtons extends Component {
  componentDidMount() {
    this.fetchIfNeeded();
  }

  componentDidUpdate() {
    this.fetchIfNeeded();
  }

  fetchIfNeeded() {
    const { role, roleId } = this.props;
    if (role.shouldLoadDeep) {
      this.props.fetchRole({ id: roleId });
    }
  }

  renderSkeleton() {
    return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
  }

  render() {
    const { role, intl } = this.props;

    if (!role.id && role.isPending) {
      return this.renderSkeleton();
    }

    if (!!role.id) {
      return (
        <span className="AuthButtons">
          <Popover
            popoverClassName="AuthButtons__popover"
            content={
              <Menu className="AuthButtons__popover__menu">
                <Link to="/point/subjects/create" className="bp4-menu-item">
                  <Icon icon="person" />
                  <div className="bp4-text-overflow-ellipsis bp4-fill">
                    Point Subject
                  </div>
                </Link>
                <Link to="/point/users/create" className="bp4-menu-item">
                  <Icon icon="new-person" />
                  <div className="bp4-text-overflow-ellipsis bp4-fill">
                    Invite User
                  </div>
                </Link>
                <MenuDivider />
                <Link to="/trace/deployments/create" className="bp4-menu-item">
                  <Icon icon="full-stacked-chart" />{' '}
                  <div className="bp4-text-overflow-ellipsis bp4-fill">
                    Trace Deployment
                  </div>
                </Link>
              </Menu>
            }
            placement="bottom-end"
            fill
          >
            <Button icon="plus" className="bp4-minimal" rightIcon="caret-down">
              Create
            </Button>
          </Popover>
        </span>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  role: selectCurrentRole(state),
  roleId: selectCurrentRoleId(state),
  metadata: selectMetadata(state),
});

CreateButtons = connect(mapStateToProps, { fetchRole })(CreateButtons);
export default injectIntl(CreateButtons);
