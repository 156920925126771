import { createReducer } from 'redux-act';

import {
    fetchVerify,
} from 'actions';

import {
    objectLoadStart,
    objectLoadError,
    objectLoadComplete,
} from 'reducers/util';

const initialState = {};

export default createReducer(
  {
    [fetchVerify.START]: (state, { id }) => objectLoadStart(state, id),
    [fetchVerify.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),
    [fetchVerify.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),
  },
  initialState
);
