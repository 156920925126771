import { createReducer } from 'redux-act';

import {
    updateUser,
    createUser,
    deleteUser,
    fetchUser,
    queryUsers
} from 'actions';

import {
    objectLoadStart,
    objectLoadError,
    objectLoadComplete,
    objectDelete, resultObjects
} from 'reducers/util';

const initialState = {};

export default createReducer(
  {
    [fetchUser.START]: (state, { id }) => objectLoadStart(state, id),
    [fetchUser.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),
    [fetchUser.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),
    [queryUsers.COMPLETE]: (state, { result }) =>
        resultObjects(state, result),
    [createUser.START]: (state, { id }) => objectLoadStart(state, id),
    [createUser.ERROR]: (state, { error, args: { id } }) =>
      objectLoadError(state, id, error),
    [createUser.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),
    [updateUser.START]: (state, { id }) => objectLoadStart(state, id),
    [updateUser.COMPLETE]: (state, { id, data }) =>
      objectLoadComplete(state, id, data),
    [updateUser.COMPLETE]: (state, { id }) => objectDelete(state, id),

  },
  initialState
);
