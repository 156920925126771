import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Button,
  FormGroup,
  Intent,
} from '@blueprintjs/core';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import {Skeleton} from "components/common";
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import {createAPIRedirect, createPaymentRedirect, updateRole, fetchAPITokens, resetAPITokens} from 'actions';
import {selectMetadata, selectCurrentRole, selectLoadingState} from 'selectors';
import {showResponseToast} from "app/toast";
import './APIScreen.scss';
import ClipboardInput from 'components/common/ClipboardInput';

const messages = defineMessages({
  title: {
    id: 'settings.title',
    defaultMessage: 'API Access',
  },
  save_button: {
    id: 'settings.save',
    defaultMessage: 'Update',
  },
  token_id: {
    id: 'settings.token_id',
    defaultMessage: 'API Token ID',
  },
  api_key: {
    id: 'settings.api_key',
    defaultMessage: 'API Token Secret',
  },
  confirm: {
    id: 'settings.confirm',
    defaultMessage: '(confirm)',
  },
  saved: {
    id: 'settings.saved',
    defaultMessage: "It's official, you're an API user.",
  },
});

export class APIScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      role: props.role,
      userTokens: {
        key_id: "Loading...",
        key_secret: "Loading...",
        usage: 0,
      },
      loadingState: {
        s_checkout: false
      }
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.accountRedirect = this.accountRedirect.bind(this);
    this.goToDocs = this.goToDocs.bind(this);
    this.resetAPIToken = this.resetAPIToken.bind(this);
  }

  static getDerivedStateFromProps(props) {
    return { role: props.role, tokens: props.tokens };
  }

  onChangeInput({ target }) {
    const { role } = this.state;
    role[target.id] = target.value;
    this.setState({ role });
  }

  async accountRedirect(){
    const {loadingState} = this.props;
    loadingState.s_checkout = true;
    const response = await this.props.createAPIRedirect();
    loadingState.s_checkout = false;
    window.location = response.url;
  }

  async componentDidMount() {
    const { intl } = this.props;
    const data = await this.props.fetchAPITokens().catch((e) => {
      showResponseToast(e.response, intl);
    });
    this.setState({
      userTokens: data.data
    })    
  }

  goToDocs(){
    window.open(`https://stric.stoplight.io/docs/footprint-api`, "_blank")
  }

  async resetAPIToken(){
    const { intl, metadata} = this.props;
    const data = this.props.resetAPITokens().catch((e) => {
      showResponseToast(e.response, intl);
    });
    this.setState({
      userTokens: data.data
    });
    window.location.href = `${metadata.app.ui_uri}manage-api`
  }

  renderForm() {
    const { intl, loadingState } = this.props;
    const { role, userTokens } = this.state;
    if (userTokens?.key_id === "Loading..."||userTokens === undefined) {
      return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
    }
    return (
      <div className="settings-form">
        <FormGroup
          label={intl.formatMessage(messages.token_id)}
          labelFor="api_key"
        >
          <ClipboardInput
            id="api_key"
            value={userTokens.key_id}
            onChange={this.onChangeInput}
            disabled
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage(messages.api_key)}
          labelFor="api_secret"
          helperText={`Your current usage for this key is: ${userTokens.usage}`}
        >
          <ClipboardInput
            id="api_secret"
            value={userTokens.key_secret}
            onChange={this.onChangeInput}
            autoFocus
            disabled
          />
        </FormGroup>
        <FormGroup>
          {role.is_billing ?
          <Button
              className="settings-form__submit"
              onClick={this.accountRedirect}
              text={role.is_subscribed ? "Manage Billing" : "Checkout"}
              loading={loadingState.s_checkout}
          /> : <></>}
          <Button style={{marginLeft: "5px"}} onClick={this.goToDocs} className="settings-form__submit" text={"API Docs"}/>
          <Button intent={Intent.WARNING} style={{marginLeft: "5px"}} onClick={this.resetAPIToken} className="settings-form__submit" text={"Reset Key"}/>
        </FormGroup>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className="SettingsScreen"
        requireSession
      >
        <Dashboard>
          <div className="Dashboard__title-container">
            <h5 className="Dashboard__title">
              {intl.formatMessage(messages.title)}
            </h5>
            <p>You recieve 100 free API calls with your Footprint account. To get more calls, sign up for our API plan.<br/>$250AUD/month for 1000 requests. Click checkout to get started.</p>
          </div>
          {this.renderForm()}
        </Dashboard>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  role: {
    ...selectCurrentRole(state),
  },
  loadingState: selectLoadingState(state)
});

APIScreen = withRouter(APIScreen);
APIScreen = connect(mapStateToProps, {resetAPITokens, fetchAPITokens, updateRole, createAPIRedirect, createPaymentRedirect })(APIScreen);
APIScreen = injectIntl(APIScreen);
export default APIScreen;
