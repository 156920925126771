import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';

export const fetchVerify = asyncActionCreator(
  ({ appId }) =>
    async () => {
      const response = await endpoint.get(`public/verification/${appId}`);
      return { id: appId, data: response.data.data };
    },
  { name: 'FETCH_USER' }
);
