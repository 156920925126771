import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Button, Checkbox,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import 'react-phone-input-2/lib/style.css'

import { connect } from 'react-redux';
import withRouter from 'app/withRouter';
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import {
  fetchDeployment,
  createDeployment,
  updateDeployment,
  deleteDeployment,
  syncDeployment,
  createSSORedirect,
} from 'actions';
import {
  selectCurrentRole,
  selectMetadata,
  selectDeployment,
  selectLoadingState
} from 'selectors';
import './DeploymentCreateScreen.scss';
import ClipboardInput from 'components/common/ClipboardInput';
import {Skeleton} from "components/common";
import {compose} from "redux";
import {showResponseToast, showSuccessToast} from "../../app/toast";


const messages = defineMessages({
  title: {
    id: 'deployment.title',
    defaultMessage: 'Deployment',
  },
  password: {
    id: 'deployment.password',
    defaultMessage: 'Initial User Password',
  },
  name: {
    id: 'deployment.name',
    defaultMessage: 'Deployment Name',
  },
  url: {
    id: 'deployment.url',
    defaultMessage: 'URL',
  },
  initial_email: {
    id: 'deployment.initial_email',
    defaultMessage: 'Initial Account Email Address',
  },
  whitelist_domain: {
    id: 'deployment.whitelist_domain',
    defaultMessage: 'Whitelist Sign-up Domain',
  },
  sso_enable: {
    id: 'deployment.sso_enable',
    defaultMessage: 'Enable SSO (OpenID Connect)',
  },
  oidc_metadata: {
    id: 'deployment.oidc_metadata',
    defaultMessage: 'OIDC Metadata Document URL',
  },
  oidc_clientId: {
    id: 'deployment.oidc_clientId',
    defaultMessage: 'OIDC Client ID',
  },
  oidc_clientSecret: {
    id: 'deployment.oidc_clientSecret',
    defaultMessage: 'OIDC Client Secret',
  },
  custom_domainEnable: {
    id: 'deployment.custom_domainEnable',
    defaultMessage: 'Enable Custom Domain',
  },
  custom_domain: {
    id: 'deployment.custom_domain',
    defaultMessage: 'Custom Domain',
  },
  custom_domainStatus: {
    id: 'deployment.custom_domainStatus',
    defaultMessage: 'Custom Domain Status',
  },
  create_button: {
    id: 'deployment.create',
    defaultMessage: 'Create',
  },
  save_button: {
    id: 'deployment.req',
    defaultMessage: 'Save Changes',
  },
  delete_button: {
    id: 'deployment.delete',
    defaultMessage: 'Delete',
  },
  sync_button: {
    id: 'deployment.sync',
    defaultMessage: 'Sync and Restart',
  },
  login_url: {
    id: 'deployment.login_url',
    defaultMessage: 'Deployment URL',
  },
});

let buttonName = "Create";
let buttonIntent = Intent.PRIMARY;

export class DeploymentCreateScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      role: props.role,
      loadingState: {
        d_createButton: false
      },
      deployment:{
        sso:{
          enabled: false
        },
        custom_domain:{
          enabled: false
        }
      }
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.createDeployment = this.createDeployment.bind(this);
    this.updateDeployment = this.updateDeployment.bind(this);
    this.deleteDeployment = this.deleteDeployment.bind(this);
    this.syncDeployment = this.syncDeployment.bind(this);
    this.onToggleCname = this.onToggleCname.bind(this);
    this.onToggleSSO = this.onToggleSSO.bind(this);
    this.onChangeSSOInput = this.onChangeSSOInput.bind(this);
    this.onChangeCDInput = this.onChangeCDInput.bind(this);
    this.validDomain = this.validDomain.bind(this);
    this.validEmail = this.validEmail.bind(this);
    this.validName = this.validName.bind(this);
    this.ssoRedirect = this.ssoRedirect.bind(this);
  }

  componentDidMount() {
    const {params:{
      deploymentId
    }, intl, metadata} = this.props;
    if(deploymentId !== "create"){
      this.props.fetchDeployment({deploymentId}).catch((e) => {
        showResponseToast(e.response, intl);
        window.location.href = `${metadata.app.ui_uri}trace/deployments`
      });
      buttonName = "Save"
      buttonIntent = Intent.WARNING
    }
  } 

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const {params:{
      deploymentId
    }, intl, metadata} = this.props;

    const {params} = prevProps;

    if ((deploymentId !== params.deploymentId) && deploymentId !== "create") {
      this.props.fetchDeployment({deploymentId}).catch((e) => {
        showResponseToast(e.response, intl);
        window.location.href = `${metadata.app.ui_uri}trace/deployments`
      });
      buttonName = "Save"
      buttonIntent = Intent.WARNING
    }
  }

  async ssoRedirect(){
    const { deployment } = this.props;
    const response = await this.props.createSSORedirect(deployment);
    window.location = response.url;
  }

  onChangeInput({ target }) {
    const { deployment } = this.props;
    deployment[target.id] = target.value;
    this.setState({deployment});
  }

  onChangeSSOInput({ target }) {
    const { deployment } = this.props;
    deployment.sso[target.id] = target.value;
    this.setState({deployment});
  }

  onChangeCDInput({ target }) {
    const { deployment } = this.props;
    deployment.custom_domain[target.id] = target.value;
    this.setState({deployment});
  }

  async updateDeployment(e){
    e.preventDefault();
    const {role, intl, metadata, loadingState, deployment} = this.props;
    loadingState.d_createButton = true;
    deployment.tenancy = role.activeTenancy
    const response = await this.props.updateDeployment({deployment}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.d_createButton = false;
      this.setState({loadingState});
    });
    if(response.data.status === "ok"){
      showSuccessToast("Success, click sync to deploy these settings.");
      window.location.href = `${metadata.app.ui_uri}trace/deployments/${deployment.id}`
    }
  }
  async syncDeployment(e){
    e.preventDefault();
    const {intl, metadata, loadingState} = this.props;
    loadingState.d_sync = true;
    const {deployment} = this.props;
    const response = await this.props.syncDeployment({id: deployment.id}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.d_sync = false;
    });
    if(response.data.status === "ok"){
      showSuccessToast("Successfully started sync.");
      window.location.href = `${metadata.app.ui_uri}trace/deployments/${deployment.id}`
    }
  }
  async deleteDeployment(e){
    e.preventDefault();
    const {intl, metadata, loadingState} = this.props;
    loadingState.d_delete = true;
    const {deployment: {id}} = this.props;
    const response = await this.props.deleteDeployment({id}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.d_delete = false;
    });
    if(response.data.status === "ok"){
      showSuccessToast("Successfully deleted.");
      window.location.href = `${metadata.app.ui_uri}trace/deployments`
    }
  }

  async createDeployment(e){
    e.preventDefault();
    const {role, intl, metadata, loadingState} = this.props;
    loadingState.d_createButton = true;
    const {deployment} = this.props;
    deployment.tenancy = role.activeTenancy
    const response = await this.props.createDeployment({deployment}).catch((e) => {
      showResponseToast(e.response, intl);
      loadingState.d_createButton = false;
    });
    if(response.data.status === "ok"){
      showResponseToast(response.raw, intl);
      window.location.href = `${metadata.app.ui_uri}trace/deployments`
    }
  }

  validName(){
    const { deployment: {
      name
    } } = this.props;
    return name !== undefined && name !== null && name.length > 2;
  }

  validEmail(){
    const { deployment:{
      initial_email
    } } = this.props;
    return initial_email !== undefined && initial_email !== null && initial_email.length > 2;
  }

  validDomain(){
    const { deployment:{
      whitelist_domain
    } } = this.props;
    return whitelist_domain !== undefined && whitelist_domain !== null && whitelist_domain.length > 2;
  }

  onToggleSSO(e){
    const { deployment } = this.props;
    deployment.sso.enabled = !deployment.sso.enabled;
    this.setState({ deployment });
  }

  onToggleCname(e){
    const { deployment } = this.props;
    deployment.custom_domain.enabled = !deployment.custom_domain.enabled;
    this.setState({ deployment });
  }
  valid() {
    return (
        this.validName() && this.validEmail() && this.validDomain()
    );
  }

  renderForm() {
    const { intl, deployment, loadingState } = this.props;
    if (deployment.isPending) {
      return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
    }
    const nameIntent = this.validName() ? undefined : Intent.DANGER;
    const emailIntent = this.validEmail() ? undefined : Intent.DANGER;
    const domainIntent = this.validDomain() ? undefined : Intent.DANGER;
    let buttons = [<Button className={"btn-space"} disabled={!this.valid()} loading={loadingState.d_createButton} onClick={ buttonName === "Save" ? this.updateDeployment : this.createDeployment} text={buttonName} intent={Intent.PRIMARY} />];
    if(buttonName === "Save"){
      buttons.push(<Button className={"btn-space"} loading={loadingState.d_delete} onClick={this.deleteDeployment} text={"Delete"} intent={Intent.DANGER} />);
      if(deployment.sync_pending){
        buttons.push(<Button className={"btn-space"} onClick={this.syncDeployment} text={"Sync"} intent={Intent.WARNING} />);
      }
    }
    if(deployment.deployment_status !== "not_created"){
      buttons.push(
        <Button className={"btn-space"} onClick={this.ssoRedirect} text={"Login"} intent={Intent.PRIMARY} />
      );
    }
    let additionalInputs = [
        <>
          {deployment.password_shown ? <></> :
              <FormGroup
                  label={intl.formatMessage(messages.password)}
                  labelFor="password"
                  helperText={"This password will only be shown once."}
              >
                <ClipboardInput id="password" icon="key" value={deployment.initial_password} />
              </FormGroup>
          }
          <Checkbox
              checked={deployment.sso.enabled}
              label={intl.formatMessage(messages.sso_enable)}
              onChange={this.onToggleSSO}
          />
          {deployment.sso.enabled ?
              <>
              <FormGroup
                  label={intl.formatMessage(messages.oidc_clientId)}
                  labelFor="client_id"
              >
                <InputGroup
                    id="client_id"
                    value={deployment.sso.client_id}
                    onChange={this.onChangeSSOInput}
                    placeholder={"client-id-from-idp"}
                />
              </FormGroup>
                <FormGroup
                    label={intl.formatMessage(messages.oidc_clientSecret)}
                    labelFor="client_secret"
                >
                  <InputGroup
                      id="client_secret"
                      value={deployment.sso.client_secret}
                      onChange={this.onChangeSSOInput}
                      placeholder={"secret value here"}
                  />
                </FormGroup>
                <FormGroup
                    label={intl.formatMessage(messages.oidc_metadata)}
                    labelFor="sso.metadata_url"
                >
                  <InputGroup
                      id="metadata_url"
                      value={deployment.sso.metadata_url}
                      onChange={this.onChangeSSOInput}
                      placeholder={"https://valid-domain.com/.well-known/openid-configuration"}
                  />
                </FormGroup>

              </>: <></>}
          <Checkbox
              checked={deployment.custom_domain.enabled}
              label={intl.formatMessage(messages.custom_domainEnable)}
              onChange={this.onToggleCname}
          />
          {deployment.custom_domain.enabled ?
              <>
                <FormGroup
                    label={intl.formatMessage(messages.custom_domain)}
                    labelFor="domain"
                >
                  <InputGroup
                      id="domain"
                      value={deployment.custom_domain.domain}
                      onChange={this.onChangeCDInput}
                      placeholder={"footprint.yourdomain.com.au"}
                  />
                </FormGroup>
              </>: <></>}
        </>]
    return (
      <div className="settings-form">
        <FormGroup
          label={intl.formatMessage(messages.name)}
          labelFor="name"
        >
          <InputGroup
            id="name"
            value={deployment.name}
            onChange={this.onChangeInput}
            autoFocus
            intent={nameIntent}
            placeholder={"Example Deployment"}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.whitelist_domain)}
            labelFor="whitelist_domain"
        >
          <InputGroup
              id="whitelist_domain"
              value={deployment.whitelist_domain}
              onChange={this.onChangeInput}
              placeholder={"example.com"}
              intent={domainIntent}
          />
        </FormGroup>
        <FormGroup
            label={intl.formatMessage(messages.initial_email)}
            labelFor="initial_email"
            helperText={buttonName === "Create" ? "The email domain must match the whitelist domain.": "You can't change this email."}
        >
          {buttonName !== "Create" ? <ClipboardInput id="initial_email" icon="person" value={deployment.initial_email} /> : <InputGroup
              id="initial_email"
              value={deployment.initial_email}
              onChange={this.onChangeInput}
              placeholder={"jane@example.com"}
              disabled={buttonName !== "Create"}
              intent={emailIntent}
          />}
        </FormGroup>
        {deployment.deployment_status !== "not_created" ?
            <FormGroup
                label={intl.formatMessage(messages.login_url)}
                labelFor="login_url"
            >
              <ClipboardInput id="login_url" icon="paperclip" value={deployment.login_url ? deployment.login_url: "URL Pending"} />
            </FormGroup>: <></>
        }
        {deployment.deployment_status !== "not_created"? additionalInputs:<></>}
        {buttons}
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className="SettingsScreen"
        requireSession
      >
        <Dashboard>
          <div className="Dashboard__title-container">
            <h5 className="Dashboard__title">
              {intl.formatMessage(messages.title, {"buttonName": buttonName})}
            </h5>
            <p>This form can be used to create and update a trace deployment.</p>
          </div>
          {this.renderForm()}
        </Dashboard>
      </Screen>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { deploymentId } = ownProps.params;
  const metadata = selectMetadata(state);
  const deployment = selectDeployment(state, deploymentId);
  const role = selectCurrentRole(state);
  const loadingState = selectLoadingState(state);
  return { metadata, deployment, role, loadingState};
};

export default compose(
    withRouter,
    connect(mapStateToProps,
    {
      fetchDeployment,
      updateDeployment,
      syncDeployment,
      deleteDeployment,
      createDeployment,
      createSSORedirect
    }),
    injectIntl,
)(DeploymentCreateScreen);


