import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { Button, Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';

import { fetchRole } from 'actions';
import {
  selectCurrentRole,
  selectCurrentRoleId,
  selectMetadata,
} from 'selectors';
import AuthenticationDialog from 'dialogs/AuthenticationDialog/AuthenticationDialog';
import { DialogToggleButton } from 'components/Toolbar';
import { Skeleton } from 'components/common';

import './AuthButtons.scss';

const messages = defineMessages({
  settings: {
    id: 'nav.settings',
    defaultMessage: 'Settings',
  },
  signout: {
    id: 'nav.signout',
    defaultMessage: 'Sign out',
  },
  myaccount: {
    id: "nav.myaccount",
    defaultMessage: "My Account"
  },
  signin: {
    id: 'nav.signin',
    defaultMessage: 'Sign in',
  },
});

export class AuthButtons extends Component {

  componentDidMount() {
    this.fetchIfNeeded();
  }

  componentDidUpdate() {
    this.fetchIfNeeded();
  }

  fetchIfNeeded() {
    const { role, roleId } = this.props;
    if (role.shouldLoadDeep) {
      this.props.fetchRole({ id: roleId });
    }
  }

  renderSkeleton() {
    return <Skeleton.Text type="span" length="10" className="AuthButtons" />;
  }

  render() {
    const { role, metadata, intl } = this.props;

    if (!role.id && role.isPending) {
      return this.renderSkeleton();
    }

    if (!!role.id) {
      return (
        <span className="AuthButtons">
          <Popover
            popoverClassName="AuthButtons__popover"
            content={
              <Menu className="AuthButtons__popover__menu">
                <Link to="/settings" className="bp4-menu-item">
                  <Icon icon="cog" />{' '}
                  <div className="bp4-text-overflow-ellipsis bp4-fill">
                    {intl.formatMessage(messages.settings)}
                  </div>
                </Link>
                <MenuItem href="https://identity.getfootprint.com.au/realms/footprint-prod/account/"
                          className="bp4-menu-item"
                          icon={"person"} text={intl.formatMessage(messages.myaccount)}>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon="log-out"
                  href="/logout"
                  text={intl.formatMessage(messages.signout)}
                />
              </Menu>
            }
            placement="bottom-end"
            fill
          >
            <Button icon="user" className="bp4-minimal" rightIcon="caret-down">
              <Truncate lines={2} width={120}>
                {role ? role.name : 'Profile'}
              </Truncate>
            </Button>
          </Popover>
        </span>
      );
    }

    if (metadata.auth.password_login_uri || metadata.auth.oauth_uri) {
      return (
        <span className="AuthButtons">
          <DialogToggleButton
            buttonProps={{
              text: intl.formatMessage(messages.signin),
              icon: 'log-in',
              className: 'bp4-minimal',
            }}
            Dialog={AuthenticationDialog}
            dialogProps={{ auth: metadata.auth }}
          />
        </span>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  role: selectCurrentRole(state),
  roleId: selectCurrentRoleId(state),
  metadata: selectMetadata(state),
});

AuthButtons = connect(mapStateToProps, { fetchRole })(AuthButtons);
export default injectIntl(AuthButtons);
