import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';
import {queryEndpoint} from "./util";

export const fetchUser = asyncActionCreator(
  ({ userId, tenancyId }) =>
    async () => {
      const response = await endpoint.get(`tenancies/${tenancyId}/users/${userId}`);
      return { id: userId, data: response.data.data };
    },
  { name: 'FETCH_USER' }
);

export const queryUsers = asyncActionCreator(
    (query) => async () => queryEndpoint(query),
    { name: 'QUERY_USERS' }
);


export const createUser = asyncActionCreator(
  ({ user, tenancyId }) =>
    async () => {
      const response = await endpoint.post(
        `tenancies/${tenancyId}/users`,
          user,
          {}
      );
      return { id: response.data.id, data: response.data };
    },
  { name: 'CREATE_USER' }
);

export const updateUser = asyncActionCreator(
  ({user, tenancyId}) => async () => {
    const response = await endpoint.patch(
      `tenancies/${tenancyId}/users/${user.id}`,
        user,
        {}
    );
    return { id: user.id, data: response.data };
  },
  { name: 'UPDATE_USER' }
);

export const deleteUser = asyncActionCreator(
  ({userId, tenancyId}) => async () => {
      const response = await endpoint.delete(`tenancies/${tenancyId}/users/${userId}`, {});
    return { id: userId, data: response.data };
  },
  { name: 'DELETE_USER' }
);
